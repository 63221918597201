import React from 'react'
import { Container, Flex, Box, Card, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ListItem from '@solid-ui-components/ListItem'
import FlexImage from '@solid-ui-components/FlexImage'
import FlexContent from '@solid-ui-components/FlexContent'
import FlexOverlapFade from '@solid-ui-components/FlexOverlapFade'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import { textContent } from '../textContent'
import ContentButtons from '../../../../solid-ui-components/src/ContentButtons/ContentButtons'
import Sticky from 'react-sticky-el'

const linksPolicy = [
  {
    "text": "Informações Gerais",
    "type": "ANCHOR",
    "link": "#informacoesgerais",
    "variant": "links.normal"
  },
  {
    "text": "1. Dados que coletamos e motivos da coleta",
    "type": "ANCHOR",
    "link": "#dadosquecoletamos",
    "variant": "links.normal"
  },
  {
    "text": "2. Compartilhamento de dados pessoais com terceiros",
    "type": "ANCHOR",
    "link": "#compartilhamentodadospessoais",
    "variant": "links.normal"
  },
  {
    "text": "3. Por quanto tempo seus dados pessoais serão armazenados",
    "type": "ANCHOR",
    "link": "#porquantotempo",
    "variant": "links.normal"
  },
  {
    "text": "4. Bases legais para o tratamento de dados pessoais",
    "type": "ANCHOR",
    "link": "#baseslegais",
    "variant": "links.normal"
  },
  {
    "text": "5. Direitos do usuário",
    "type": "ANCHOR",
    "link": "#direitosdousuario",
    "variant": "links.normal"
  },
  {
    "text": "6. Medidas de segurança no tratamento de dados pessoais",
    "type": "ANCHOR",
    "link": "#medidasdeseguranca",
    "variant": "links.normal"
  },
  {
    "text": "7. Reclamação a uma autoridade de controle",
    "type": "ANCHOR",
    "link": "#reclamacaoautoridade",
    "variant": "links.normal"
  },
  {
    "text": "8. Alterações nesta política",
    "type": "ANCHOR",
    "link": "#alteracoesnestapolitica",
    "variant": "links.normal"
  },
  {
    "text": "9. Como entrar em contato conosco",
    "type": "ANCHOR",
    "link": "#comoentraremcontato",
    "variant": "links.normal"
  },
]


const PrivacyPolicyContent = () => {
  return (
    <Container>
      <Flex
        sx={{
          alignItems: [`space-between`],
          justifyContend: 'center',
          flexDirection: [
            `column-reverse`,
            `row`
          ],
          mx: [null, null, null, null]
        }}
      >
        <Box sx={{ textAlign: ['center', 'left'] }}>
          <ContentText content={textContent} />
        </Box>
        <Container sx={{
          width: '150%',
          fontSize: '14px',
          padding: '0',
          display: [`none`, `block`]
        }}>
          <Sticky boundaryElement=".privacypolicycontent" hideOnBoundaryHit={false} stickyStyle={{ paddingTop: '100px', paddingBottom: '50px' }}>
            <Box sx={{ borderLeft: '2px solid orange', paddingLeft: '15px' }}>
              <ContentButtons content={linksPolicy} style={{ margin: '0', padding: '0' }} />
            </Box>
          </Sticky>
        </Container>
      </Flex>
      <FlexOverlapFade direction={'rtl'} />
    </Container>
  )
}

export default PrivacyPolicyContent
