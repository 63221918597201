export const textContent = [
	{ text: 'POLÍTICA DE PRIVACIDADE', variant: 'h2', mb: 4 },
	{ text: 'Este aplicativo é mantido e operado por JUIT Tecnologias Para O Direito LTDA.', variant: 'p', id: 'informacoesgerais' },
	{ text: 'Nós coletamos e utilizamos alguns dados pessoais que pertencem àqueles que utilizam nosso aplicativo.  Ao  fazê-lo,  agimos  na  qualidade  de  controlador  desses  dados  e  estamos  sujeitos às  disposições  da  Lei  Federal  n.  13.709/2018  (Lei  Geral  de  Proteção  de  Dados  Pessoais  - LGPD).', variant: 'p' },
	{ text: 'Nós  cuidamos  da  proteção  de  seus  dados  pessoais  e,  por  isso,  disponibilizamos  esta  política de privacidade, que contém informações importantes sobre:', variant: 'p' },
	{ text: '- Quem deve utilizar nosso aplicativo;', variant: 'small' },
	{ text: '- Quais dados coletamos e o que fazemos com eles;', variant: 'small' },
	{ text: '- Seus direitos em relação aos seus dados pessoais; e', variant: 'small' },
	{ text: '- Como entrar em contato conosco.', variant: 'small' },
	{ text: '1. Dados que coletamos e motivos da coleta', variant: 'h4', id: 'dadosquecoletamos', mt: 4 },
	{ text: 'Nosso  aplicativo  coleta  e  utiliza  alguns  dados  pessoais  de  nossos  usuários,  de  acordo  com  o disposto nesta seção.', variant: 'p' },
	{ text: '1. Dados pessoais fornecidos expressamente pelo usuário', variant: 'p' },
	{ text: 'Nós coletamos os seguintes dados pessoais que nossos usuários nos fornecem expressamente ao utilizar nosso aplicativo:', variant: 'p' },
	{ text: '- Nome completo;', variant: 'small' },
	{ text: '- Empresa (opcional);', variant: 'small' },
	{ text: '- CPF;', variant: 'small' },
	{ text: '- Endereço de e-mail;', variant: 'small' },
	{ text: '- Número de telefone;', variant: 'small' },
	{ text: '- Dados do cartão de crédito (número, data de validade, CVC e endereço de faturamento) - embora sem armazenamento;', variant: 'small' },
	{ text: '- Mensagens de contato.', variant: 'small' },
	{ text: 'A coleta destes dados ocorre nos seguintes momentos:', variant: 'p', mt: 3 },
	{ text: '- quando o usuário faz seu cadastro no site; ', variant: 'small' },
	{ text: '- quando o usuário utiliza o formulário de contato;', variant: 'small' },
	{ text: '-  quando  o  usuário  realiza  ações  na  aplicação  (como  cliques,  visualização  de  páginas  e outros eventos); ', variant: 'small' },
	{ text: '- quando o usuário realiza uma assinatura por meio de uma compra.', variant: 'small' },
	{ text: 'Os dados fornecidos por nossos usuários são coletados com as seguintes finalidades:', variant: 'p', mt: 3 },
	{ text: '- para que o usuário possa se cadastrar para obter um teste grátis;', variant: 'small' },
	{ text: '- para que o usuário possa realizar uma assinatura;', variant: 'small' },
	{ text: '- para que o usuário possa acionar o suporte;', variant: 'small' },
	{ text: '- para que as pessoas possam entrar em contato conosco;', variant: 'small' },
	{ text: '- para que nós possamos realizar a cobrança das assinaturas para os usuários cadastrados;', variant: 'small' },
	{ text: '- para que nós possamos recolher os impostos devidos;', variant: 'small' },
	{ text: '- para que nós possamos melhorar a experiência de navegação da plataforma;', variant: 'small' },
	{ text: '-  para  que  nós  possamos  identificar  o  plano  contratado  e  entregar  as  funcionalidades referentes ao plano;', variant: 'small' },
	{ text: '- para que nós possamos enviar comunicações a nossos usuários.', variant: 'small', space: 2 },
	{ text: '2. Dados pessoais obtidos de outras formas', variant: 'p', mt: 3 },
	{ text: 'Não  serão  coletados  dados  sensíveis  de  nossos  usuários,  assim  entendidos  aqueles  definidos nos arts. 11 e seguintes da Lei de Proteção de Dados Pessoais. Assim, não haverá coleta de dados sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.', variant: 'p' },
	{ text: '3. Dados sensíveis', variant: 'p', mt: 3 },
	{ text: 'Não  serão  coletados  dados  sensíveis  de  nossos  usuários,  assim  entendidos  aqueles  definidos nos  arts.  11  e  seguintes  da  Lei  de  Proteção  de  Dados  Pessoais.  Assim,  não  haverá  coleta  de dados sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.', variant: 'p' },
	{ text: '4. Coleta de dados não previstos expressamente', variant: 'p', mt: 3 },
	{ text: 'Eventualmente,   outros   tipos   de   dados   não   previstos   expressamente   nesta   Política   de Privacidade  poderão  ser  coletados,  desde  que  sejam  fornecidos  com  o  consentimento  do usuário,  ou,  ainda,  que  a  coleta  seja  permitida  com  fundamento  em  outra  base  legal  prevista em lei.', variant: 'p' },
	{ text: 'Em  qualquer  caso,  a  coleta  de  dados  e  as  atividades  de  tratamento  dela  decorrentes  serão informadas aos usuários do aplicativo.', variant: 'p' },
	{ text: '2. Compartilhamento de dados pessoais com terceiros', variant: 'h4', id: 'compartilhamentodadospessoais', mt: 4 },
	{ text: 'Nós compartilhamos alguns dos dados pessoais mencionados nesta seção com terceiros.', variant: 'p' },
	{ text: 'Os dados compartilhados são os seguintes:', variant: 'p' },
	{ text: 'Alguns  dados  de  navegação  são  compartilhados  com  a  empresa  Segment.io,  Inc. (“Segment”  -  https://segment.com/),  que  reúne  dados  sobre  a  utilização  de  nossos produtos. A Segment ainda compartilha as informações com outras plataformas definidas por  nós,  como  a  Intercom,  Inc.  (“Intercom”  -  https://intercom.com/),  Pipedrive  Inc. (“Pipedrive”  -  https://pipedrive.com/),  Heap  Inc.  (“Heap”  -  https://heap.io/),  Mixpanel, Inc.    (“Mixpanel”    -    https://mixpanel.com/),    Amplitude,    Inc.    ("Amplitude"    - https://amplitude.com/) e Peaberry Software, Inc. (“Customer.io” - https://customer.io/).', variant: 'small', pl: 4 },
	{ text: 'Estes dados são compartilhados pelas seguintes razões e para as seguintes finalidades:', variant: 'p', mt: 4 },
	{ text: 'O  compartilhamento  acontece  para  que  seja  possível  a)  entender  como  a  plataforma  é utilizada  por  nossos  usuários  de  forma  a  entregar  funcionalidades  que  sejam  mais apropriadas e gerem mais valor, b) permitir que os usuários entrem em contato conosco para atendimentos e c) permitir que a JUIT entre em contato com os usuários para enviar informativos de novas funcionalidades e ofertas de produtos.', variant: 'small', pl: 4 },
	{ text: 'Além das situações aqui informadas, é possível que compartilhemos dados com terceiros para cumprir  alguma  determinação  legal  ou  regulatória,  ou,  ainda,  para  cumprir  alguma  ordem expedida por autoridade pública.', variant: 'p', mt: 4 },
	{ text: 'Em  qualquer  caso,  o  compartilhamento  de  dados  pessoais  observará  todas  as  leis  e  regras aplicáveis, buscando sempre garantir a segurança dos dados de nossos usuários, observados os padrões técnicos empregados no mercado.', variant: 'p' },
	{ text: '3. Por quanto tempo seus dados pessoais serão armazenados', variant: 'h4', id: 'porquantotempo', mt: 4 },
	{ text: 'Os  dados  pessoais  coletados  pelo  aplicativo  são  armazenados  e  utilizados  por  período  de tempo que corresponda ao necessário para atingir as finalidades elencadas neste documento e que  considere  os  direitos  de  seus  titulares,  os  direitos  do  controlador  do  aplicativo  e  as disposições legais ou regulatórias aplicáveis.', variant: 'p' },
	{ text: 'Uma vez expirados os períodos de armazenamento dos dados pessoais, eles são removidos de nossas bases de dados ou anonimizados, salvo nos casos em que houver a possibilidade ou a necessidade de armazenamento em virtude de disposição legal ou regulatória.', variant: 'p' },
	{ text: '4. Bases legais para o tratamento de dados pessoais', variant: 'h4', id: 'baseslegais', mt: 4 },
	{ text: 'Cada operação de tratamento de dados pessoais precisa ter um fundamento jurídico, ou seja, uma base legal, que nada mais é que uma justificativa que a autorize, prevista na Lei Geral de Proteção de Dados Pessoais.', variant: 'p' },
	{ text: 'Todas  as  Nossas  atividades  de  tratamento  de  dados  pessoais  possuem  uma  base  legal  que  as fundamenta, dentre as permitidas pela legislação. Mais informações sobre as bases legais que utilizamos  para  operações  de  tratamento  de  dados  pessoais  específicas  podem  ser  obtidas  a partir de nossos canais de contato, informados ao final desta Política.', variant: 'p' },
	{ text: '5. Direitos do usuário', variant: 'h4', id: 'direitosdousuario', mt: 4 },
	{ text: 'O usuário do aplicativo possui os seguintes direitos, conferidos pela Lei de Proteção de Dados Pessoais:', variant: 'p' },
	{ text: '- confirmação da existência de tratamento;', variant: 'small' },
	{ text: '- acesso aos dados;', variant: 'small' },
	{ text: '- correção de dados incompletos, inexatos ou desatualizados;', variant: 'small' },
	{ text: '- anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na lei;', variant: 'small' },
	{ text: '- portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;', variant: 'small' },
	{ text: '- eliminação  dos  dados  pessoais  tratados  com  o  consentimento  do  titular,  exceto  nos casos previstos em lei;', variant: 'small' },
	{ text: '- informação  das  entidades  públicas  e  privadas  com  as  quais  o  controlador  realizou  uso compartilhado de dados;', variant: 'small' },
	{ text: '- informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;', variant: 'small' },
	{ text: '- revogação do consentimento.', variant: 'small' },
	{ text: 'É importante destacar  que,  nos  termos da LGPD, não existe um direito  de  eliminação de dados tratados com fundamento em bases legais distintas do consentimento,  a  menos  que os dados sejam desnecessários, excessivos ou tratados em desconformidade com o previsto na lei.', variant: 'p', mt: 3 },
	{ text: '1. Como o titular pode exercer seus direitos', variant: 'p' },
	{ text: 'Os  titulares  de  dados  pessoais  tratados  por  nós  poderão  exercer  seus  direitos  por  meio  do formulário disponibilizado no seguinte caminho: dpo@juit.io. Alternativamente, se desejar, o titular poderá enviar um e-mail ou uma correspondência ao nosso Encarregado de Proteção de Dados  Pessoais.  As  informações  necessárias  para  isso  estão  na  seção  "Como  entrar  em contato conosco" desta Política de Privacidade.', variant: 'p' },
	{ text: 'Os titulares de dados pessoais tratados por nós poderão exercer seus direitos a partir do envio de  mensagem  ao  nosso  Encarregado  de  Proteção  de  Dados  Pessoais,  seja  por  e-mail  ou  por correspondência.  As  informações  necessárias  para  isso  estão  na  seção  "Como  entrar  em contato conosco" desta Política de Privacidade.', variant: 'p' },
	{ text: 'Para  garantir  que  o  usuário  que  pretende  exercer  seus  direitos  é,  de  fato,  o  titular  dos  dados pessoais  objeto  da  requisição,  poderemos  solicitar  documentos  ou  outras  informações  que possam auxiliar em sua correta identificação, a fim de resguardar nossos direitos e os direitos de  terceiros.  Isto  somente  será  feito,  porém,  se  for  absolutamente  necessário,  e  o  requerente receberá todas as informações relacionadas.', variant: 'p' },
	{ text: '6. Medidas de segurança no tratamento de dados pessoais', variant: 'h4', mt: 4, id: 'medidasdeseguranca' },
	{ text: 'Empregamos medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, extravio ou alteração desses dados.', variant: 'p' },
	{ text: 'As  medidas  que  utilizamos  levam  em  consideração  a  natureza  dos  dados,  o  contexto  e  a finalidade  do  tratamento,  os  riscos  que  uma  eventual  violação  geraria  para  os  direitos  e liberdades  do  usuário,  e  os  padrões  atualmente  empregados  no  mercado  por  empresas semelhantes à nossa.', variant: 'p' },
	{ text: 'Entre as medidas de segurança adotadas por nós, destacamos as seguintes:', variant: 'p' },
	{ text: '- Os dados de nossos usuários são armazenados em ambiente seguro;', variant: 'small' },
	{ text: '-  Limitamos  o  acesso  aos  dados  de  nossos  usuários,  de  modo  que  terceiros  não autorizados não possam acessá-los;', variant: 'small' },
	{ text: '- Utilizamos certificado SSL (Secure Socket Layer), de modo que a transmissão de dados entre  os  dispositivos  dos  usuários  e  nossos  servidores  aconteça  de  forma  criptografada;', variant: 'small' },
	{ text: '-  Mantemos  registros  de  todos  aqueles  que  têm,  de  alguma  forma,  contato  com  nossos dados.', variant: 'small' },
	{ text: 'Informamos,  ainda,  que  possuímos  certificação  ISO  27001.  Seguimos,  portanto,  os  mais elevados  padrões  técnicos  de  segurança  da  informação,  a  fim  de  que  nos  seja  possível proteger os dados pessoais e não pessoais de nossos usuários.', variant: 'p', mt: 4 },
	{ text: 'Ainda que adote tudo o que está ao seu alcance para evitar incidentes de segurança, é possível que  ocorra  algum  problema  motivado  exclusivamente  por  um  terceiro  -  como  em  caso  de ataques de hackers ou crackers ou, ainda, em caso de culpa exclusiva do usuário, que ocorre, por exemplo, quando ele mesmo transfere seus dados a terceiro. Assim, embora sejamos, em geral, responsáveis pelos dados pessoais que tratamos, nos eximimos de responsabilidade caso ocorra  uma  situação  excepcional  como  essas,  sobre  as  quais  não  temos  nenhum  tipo  de controle.', variant: 'p' },
	{ text: 'De qualquer forma, caso ocorra qualquer tipo de incidente de segurança que possa gerar risco ou  dano  relevante  para  qualquer  de  nossos  usuários,  comunicaremos  os  afetados  e  a Autoridade  Nacional  de  Proteção  de  Dados  acerca  do  ocorrido,  em  conformidade  com  o disposto na Lei Geral de Proteção de Dados.', variant: 'p' },
	{ text: '7. Reclamação a uma autoridade de controle', variant: 'h4', id: 'reclamacaoautoridade', mt: 4 },
	{ text: 'Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, os titulares de dados pessoais  que  se  sentirem,  de  qualquer  forma,  lesados,  podem  apresentar  reclamação  à Autoridade Nacional de Proteção de Dados.', variant: 'p' },
	{ text: '8. Alterações nesta política', variant: 'h4', id: 'alteracoesnestapolitica', mt: 4 },
	{ text: 'A presente versão desta Política de Privacidade foi atualizada pela última vez em: 19/07/2021.', variant: 'p' },
	{ text: 'Reservamo-nos   o   direito   de   modificar,   a   qualquer   momento,   as   presentes   normas, especialmente  para  adaptá-las  às  eventuais  alterações  feitas  em  nosso  aplicativo,  seja  pela disponibilização  de  novas  funcionalidades,  seja  pela  supressão  ou  modificação  daquelas  já existentes.', variant: 'p' },
	{ text: 'Sempre que houver uma modificação, nossos usuários serão notificados acerca da mudança.', variant: 'p' },
	{ text: '9. Como entrar em contato conosco', variant: 'h4', id: 'comoentraremcontato', mt: 4 },
	{ text: 'Para  esclarecer  quaisquer  dúvidas  sobre  esta  Política  de  Privacidade  ou  sobre  os  dados pessoais  que  tratamos,  entre  em  contato  com  nosso  Encarregado  de  Proteção  de  Dados Pessoais, por algum dos canais mencionados abaixo:', variant: 'p' },
	{ text: 'E-mail: dpo@juit.io', variant: 'small' },
	{ text: 'Telefone: +55 11 95600-5848', variant: 'small' },
]

export default textContent